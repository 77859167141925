import { useNickFetch } from "#app/hooks/useNickFetch";
import { useNotice } from "#app/hooks/useNotice";
import { useShopPageProps } from "#app/layouts/ShopLayout";
import { PostStatus, ShopSync } from "#app/models";
import { sleep } from "#app/utils";
import InstagramIcon from "#assets/instagram.png";
import dayjs from "#app/utils/custom_dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import { Avatar, Box, Button, Card, CardContent, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { SERVER_HOST, SERVER_SCHEME } from "#app/const";
import SyncStatusBar from "#app/components/SyncStatusBar";

/**
 * リスト内のフォーム処理は別コンポーネントにしたほうがスッキリしそうという事に気付いたが時すでにお寿司.
 * どのフォームを変更したかとか判定するためにリスト内から毎回探すの大変だし..
 */

type InstagramUser = {
	id: number,
	name: string,
	shop_id: number,
	origin_uid: string,
	account_type: string,
	sync: ShopSync,
	regist_dt: string,
	update_dt: string,
	migrated: Boolean,
}
type GetInstagramUsersResponse = {
	instagram_users: InstagramUser[],
}

export default function ShopSettingsSyncInstagram() {
	const { shop } = useShopPageProps();
	const { nickFetch } = useNickFetch();
	const [deleting, setDeleting] = useState<number[]>([]);
	const [saving, setSaving] = useState<number[]>([]);
	const { addNotice } = useNotice();
	const [instagramUsers, setInstagramUsers] = useState<InstagramUser[]>([]);

	/**
	 * インスタ連携の一覧.
	 */
	const loadInstagramUsers = async () => {
		const res = await nickFetch("/get_instagram_users/", {
			shop_id: shop.id,
		});
		if (!res.error) {
			// ok.
			const data = res.data as GetInstagramUsersResponse;
			// console.log(data);
			if (data.instagram_users) {
				const list = [];
				for (const instagramUser of data.instagram_users) {
					instagramUser.sync = new ShopSync(instagramUser.sync);
					instagramUser.migrated = Boolean(parseInt(instagramUser.migrated.toString()));
					list.push(instagramUser);
				}
				setInstagramUsers(list);
			}
		}
	}
	useEffect(() => {
		// インスタ連携の一覧取得.
		loadInstagramUsers();
	}, []);

	/**
	 * PostStatus変更.
	 */
	const handleChangePostStatus = (instagram_user_id: number, e: any) => {
		const post_status = parseInt(e.target.value) as PostStatus;
		for (const instagramUser of instagramUsers) {
			if (instagramUser.id != instagram_user_id) continue;
			instagramUser.sync.post_status = post_status;
		}
		setInstagramUsers([...instagramUsers]);
	}
	const handleChangeEnabled = (instagram_user_id: number, e: any) => {
		const enabled = Boolean(parseInt(e.target.value));
		for (const instagramUser of instagramUsers) {
			if (instagramUser.id != instagram_user_id) continue;
			instagramUser.sync.enabled = enabled;
		}
		setInstagramUsers([...instagramUsers]);
	}
	/**
	 * 認証解除.
	 */
	const disableInstagramUser = async (instagram_user_id: number) => {
		setDeleting([...saving, instagram_user_id]);
		/**
		 * API叩く.
		 */
		const body = {
			instagram_user_id: instagram_user_id,
		}
		const res = await nickFetch("/delete_instagram_user/", body);
		if (!res.error) {
			// ok.
			await loadInstagramUsers();
			addNotice("連携解除しました", "success");
		}
		setDeleting(saving.filter(id => instagram_user_id != id));
	};
	/**
	 * 設定保存.
	 */
	const saveInstagramUser = async (instagram_user_id: number) => {
		setSaving([...saving, instagram_user_id]);
		let instagramUser = instagramUsers.find(item => item.id == instagram_user_id);
		if (!instagramUser) throw new Error("notfound instagramUser");
		/**
		 * API叩く.
		 */
		const body = {
			shop_id: shop.id,
			sync: instagramUser.sync,
		}
		const res = await nickFetch("/save_shop_sync/", body);
		if (!res.error) {
			// ok.
			addNotice("保存しました", "success");
		}
		setSaving(saving.filter(id => instagram_user_id != id));
	}

	// インスタ連携するURL.
	const instagram_auth_url = SERVER_SCHEME + "://" + SERVER_HOST + "/instagram/auth/?shop_id=" + shop.id + "&from=" + encodeURIComponent(window.location.href);

	return (
		<>
			<Box>
				<Typography mt={2} mb={3} sx={{ fontSize: ".9rem", fontWeight: "bold" }}>インスタグラム連携</Typography>

				<Grid container spacing={2}>
					{instagramUsers.map((instagramUser, i) => (
						<Grid key={i} item xl={3} lg={4} md={6} xs={12}>
							<Card sx={{ position: "relative" }}>
								<Box sx={{ px: 1, py: 0.5, backgroundColor: "rgba(0,0,0,0.03)", display: "flex" }}>
									<Avatar variant="square" sx={{ mr: 1, width: 24, height: 24 }} src={InstagramIcon} />
									<Typography sx={{ fontWeight: "bold" }} title={dayjs(instagramUser.sync.start_dt).format("YYYY年M月D日") + "から取り込み"}>
										{instagramUser.name}
									</Typography>
									<Box sx={{ ml: "auto" }}>
										<LoadingButton
											variant="text"
											color="error"
											size="small"
											onClick={() => { if (window.confirm("本当に解除しますか？")) disableInstagramUser(instagramUser.id); }}
											loading={!!deleting.find(v => v == instagramUser.id)}
										>連携解除</LoadingButton>
									</Box>
								</Box>
								<CardContent>
									{!instagramUser.migrated ? (<>
										<Box sx={{ p:1, mb:1, border:"2px solid #f00", background:"#fff", color:"#f00" }}>
											<Typography>再連携が必要です。「＋アカウント追加」ボタンから再連携を行ってください。</Typography>
										</Box>
									</>) : null}
									<Box sx={{ py: 1 }}>
										<FormControl>
											<FormLabel>ステータス</FormLabel>
											<SyncStatusBar sync={instagramUser.sync} />
										</FormControl>
									</Box>
									<Box sx={{ py: 1 }}>
										<FormControl>
											<FormLabel>取り込み</FormLabel>
											<RadioGroup
												row
												onChange={(e) => { handleChangeEnabled(instagramUser.id, e) }}
												value={instagramUser.sync.enabled ? "1" : "0"} >
												<FormControlLabel control={<Radio value="0" />} label="無効" />
												<FormControlLabel control={<Radio value="1" />} label="有効" />
											</RadioGroup>
										</FormControl>
									</Box>
									<Box sx={{ py: 1 }}>
										<FormControl>
											<FormLabel>投稿設定</FormLabel>
											<RadioGroup
												row
												onChange={(e) => { handleChangePostStatus(instagramUser.id, e) }}
												value={instagramUser.sync.post_status} >
												<FormControlLabel control={<Radio value={PostStatus.DRAFT} />} label="下書きとして保存" />
												<FormControlLabel control={<Radio value={PostStatus.POSTING_WAIT} />} label="自動投稿" />
											</RadioGroup>
										</FormControl>
									</Box>
									<LoadingButton
										variant="outlined"
										onClick={() => { saveInstagramUser(instagramUser.id) }}
										loading={!!saving.find(v => v == instagramUser.id)}
									>保存</LoadingButton>
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>
				<Button
					variant="outlined"
					href={instagram_auth_url}
					sx={{ mt: 2 }}
				>＋アカウント追加</Button>
			</Box>
		</>
	);
}
